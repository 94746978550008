@charset "utf-8";

/* -------- CLEAR -------- */

.clear {
	clear: both;
}

.cf:after {
	clear: both;
	display: block;
	content: "";
}

/* -------- CLEAR -------- */

/******************************************************

レイアウト
 
******************************************************/

.fLeft {
	float: left;
}

.fRight {
	float: right;
}

.mAuto {
	margin: auto;
}

.noDisp {
	display: none;
}

.txtLeft {
	text-align: left;
}

.txtRight {
	text-align: right;
}

.center {
	text-align: center;
}

/******************************************************

フォント
 
******************************************************/
.txt18 {
	font-size: 18px;
	font-size: 1.8rem;
	-webkit-text-size-adjust: none;
}

.txt15 {
	font-size: 15px;
	font-size: 1.5rem;
	-webkit-text-size-adjust: none;
}

.txt14 {
	font-size: 14px;
	font-size: 1.4rem;
	-webkit-text-size-adjust: none;
}

.txt13 {
	font-size: 13px;
	font-size: 1.3rem;
	-webkit-text-size-adjust: none;
}

.txt12 {
	font-size: 12px;
	font-size: 1.2rem;
	-webkit-text-size-adjust: none;
}

.txt11 {
	font-size: 11px;
	font-size: 1.1rem;
	-webkit-text-size-adjust: none;
}

.txt10 {
	font-size: 10px;
	font-size: 1rem;
	-webkit-text-size-adjust: none;
}

.txt9 {
	font-size: 9px;
	font-size: .9rem;
	-webkit-text-size-adjust: none;
}

.txt8 {
	font-size: 8px;
	font-size: .8rem;
	-webkit-text-size-adjust: none;
}

.bold {
	font-weight: bold;
}

.underline {
	text-decoration: underline;
}

/******************************************************

margin/padding
 
******************************************************/
.ml0 {
	margin-left: 0 !important;
}

.ml5 {
	margin-left: 5px !important;
}

.ml10 {
	margin-left: 10px !important;
}

.ml15 {
	margin-left: 15px !important;
}

.ml20 {
	margin-left: 20px !important;
}

.ml25 {
	margin-left: 25px !important;
}

.ml30 {
	margin-left: 30px !important;
}

.ml35 {
	margin-left: 35px !important;
}

.ml40 {
	margin-left: 50px !important;
}

.mr0 {
	margin-right: 0 !important;
}

.mr5 {
	margin-right: 5px !important;
}

.mr10 {
	margin-right: 10px !important;
}

.mr15 {
	margin-right: 15px !important;
}

.mr20 {
	margin-right: 20px !important;
}

.mr25 {
	margin-right: 25px !important;
}

.mr30 {
	margin-right: 30px !important;
}

.mr35 {
	margin-right: 35px !important;
}

.mr40 {
	margin-right: 40px !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mb5 {
	margin-bottom: 5px !important;
}

.mb10 {
	margin-bottom: 10px !important;
}

.mb15 {
	margin-bottom: 15px !important;
}

.mb20 {
	margin-bottom: 20px !important;
}

.mb25 {
	margin-bottom: 25px !important;
}

.mb30 {
	margin-bottom: 30px !important;
}

.mb35 {
	margin-bottom: 35px !important;
}

.mb40 {
	margin-bottom: 40px !important;
}

.mb45 {
	margin-bottom: 45px !important;
}

.mb50 {
	margin-bottom: 50px !important;
}

.mb60 {
	margin-bottom: 60px !important;
}

.mt0 {
	margin-top: 0 !important;
}

.mt5 {
	margin-top: 5px !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mt15 {
	margin-top: 15px !important;
}

.mt20 {
	margin-top: 20px !important;
}

.mt25 {
	margin-top: 25px !important;
}

.mt30 {
	margin-top: 30px !important;
}

.mt35 {
	margin-top: 35px !important;
}

.mt40 {
	margin-top: 40px !important;
}

.mt45 {
	margin-top: 45px !important;
}

.mt50 {
	margin-top: 50px !important;
}

.mt55 {
	margin-top: 55px !important;
}

.mt60 {
	margin-top: 60px !important;
}

.mt65 {
	margin-top: 65px !important;
}

.mt70 {
	margin-top: 70px !important;
}

.mt80 {
	margin-top: 80px !important;
}

.mt90 {
	margin-top: 90px !important;
}

.mt100 {
	margin-top: 100px !important;
}

.mt150 {
	margin-top: 150px !important;
}

.mt180 {
	margin-top: 180px !important;
}

.pb0 {
	padding-bottom: 0 !important;
}

.pb5 {
	padding-bottom: 5px !important;
}

.pb10 {
	padding-bottom: 10px !important;
}

.pb15 {
	padding-bottom: 15px !important;
}

.pb20 {
	padding-bottom: 20px !important;
}

.pb25 {
	padding-bottom: 25px !important;
}

.pb30 {
	padding-bottom: 30px !important;
}

.pb35 {
	padding-bottom: 35px !important;
}

.pb40 {
	padding-bottom: 40px !important;
}

.pt0 {
	padding-top: 0 !important;
}

.pt5 {
	padding-top: 5px !important;
}

.pt10 {
	padding-top: 10px !important;
}

.pt15 {
	padding-top: 15px !important;
}

.pt20 {
	padding-top: 20px !important;
}

.pt25 {
	padding-top: 25px !important;
}

.pt30 {
	padding-top: 30px !important;
}

.pt35 {
	padding-top: 35px !important;
}

.pt40 {
	padding-top: 40px !important;
}

.pt50 {
	padding-top: 50px !important;
}

.pt60 {
	padding-top: 60px !important;
}

.pt70 {
	padding-top: 70px !important;
}

.pt80 {
	padding-top: 80px !important;
}

.pl0 {
	padding-left: 0 !important;
}

.pl5 {
	padding-left: 5px !important;
}

.pl10 {
	padding-left: 10px !important;
}

.pl15 {
	padding-left: 15px !important;
}

.pl20 {
	padding-left: 20px !important;
}

.pl25 {
	padding-left: 25px !important;
}

.pl30 {
	padding-left: 30px !important;
}

.pl35 {
	padding-left: 35px !important;
}

.pl40 {
	padding-left: 40px !important;
}

.pl50 {
	padding-left: 50px !important;
}

.pr0 {
	padding-right: 0 !important;
}

.pr5 {
	padding-right: 5px !important;
}

.pr10 {
	padding-right: 10px !important;
}

.pr15 {
	padding-right: 15px !important;
}

.pr20 {
	padding-right: 20px !important;
}

.pr25 {
	padding-right: 25px !important;
}

.pr30 {
	padding-right: 30px !important;
}

.pr35 {
	padding-right: 35px !important;
}

.pr40 {
	padding-right: 40px !important;
}
